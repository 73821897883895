import { Navigation } from '@nuuday/ys-navigation';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import cx from 'classnames';
import { Alert } from 'components/Alert';
import { handleMitIDErrorCodes } from 'components/Authentication/MitID';
import { ImpersonationBanner } from 'components/Impersonation/ImpersonationBanner';
import { LeftNavigationButtonBar } from 'components/LeftNavigationButtonBar';
import { ScrollToTop } from 'components/ScrollToTop';
import { LeftNav } from 'components/navigation/Components/LeftNav/LeftNav';
import { createBrowserHistory } from 'history';
import { useWebMessenger } from 'hooks/useWebMessenger';
import NotFound from 'pages/NotFound';
import React, { useEffect } from 'react';
import { Router, Switch, useLocation } from 'react-router-dom';
import { SentryRoute, createPages } from 'routes';
import { getParameterizedUrl } from 'utils/format';
import { identity } from 'utils/identity';
import { initialize as initializeTracking, useTrackPageView } from 'utils/user-journey';
import { isUserAgent } from './utils/user-agent';

import { SubscriptionsProvider, useSubscriptionsContext } from 'SubscriptionsContext';
import './styles.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2, // retry 2 times instead of the default 3 times
    },
  },
});

const WrapperWithMessenger = () => {
  useWebMessenger();
  return <></>;
};

export const history = createBrowserHistory();

/**
 * This component allows us to access `useLocation` such that we can preserve query params when
 * redirecting
 * */
const AppRoutes = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { isFrozen } = useSubscriptionsContext();
  const location = useLocation();
  useTrackPageView({
    pageName: getParameterizedUrl(location.pathname),
  });

  return (
    <Switch>
      {createPages(location, isLoggedIn, isFrozen)}
      <SentryRoute path='*'>
        <NotFound />
      </SentryRoute>
    </Switch>
  );
};

const AppRoot = () => {
  handleMitIDErrorCodes();

  // initialize tracking
  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      initializeTracking();
    }

    // no-navpanel-left class is neseccary for mq mixin
    // on web it is set in initLeftNav.ts, which is excluded from the mobile app
    if (
      isUserAgent(['youseeSelfcareApp', 'netcrackerApp']) &&
      !document.body.classList.contains('no-navpanel-left')
    ) {
      document.body.classList.add('no-navpanel-left');
    }
  }, []);

  const navigationParams = {
    loggedIn: Boolean(identity?.isLoggedIn),
    firstName: identity?.firstname || undefined,
    lastName: identity?.lastname || undefined,
  };

  return (
    <main
      data-hj-suppress
      className={cx('ng-root', {
        ['is-mobile-app']: isUserAgent(['youseeSelfcareApp', 'netcrackerApp']),
      })}>
      <QueryClientProvider client={queryClient}>
        <SubscriptionsProvider isLoggedIn={!!identity.isLoggedIn}>
          <Router history={history}>
            <ScrollToTop />
            {!isUserAgent(['youseeSelfcareApp', 'netcrackerApp']) && (
              <>
                <Navigation
                  hideBasketIcon
                  env={process.env.NODE_ENV === 'production' ? 'prod' : 'test'}
                  params={navigationParams}
                />
                {process.env.NODE_ENV === 'production' && !identity.isLoggedIn ? null : (
                  <>
                    <LeftNavigationButtonBar />
                    <LeftNav />
                  </>
                )}
              </>
            )}
            <ImpersonationBanner />
            <Alert />
            <WrapperWithMessenger />
            <ErrorBoundary>
              <AppRoutes isLoggedIn={!!identity.isLoggedIn} />
            </ErrorBoundary>
            {process.env.NODE_ENV === 'development' ? (
              <ReactQueryDevtools initialIsOpen={false} />
            ) : null}
          </Router>
        </SubscriptionsProvider>
      </QueryClientProvider>
      <div id='virtual-assistant'></div>
    </main>
  );
};

export default AppRoot;
